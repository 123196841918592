.next-steps .fa-link {
  margin-right: 5px;
}

/* Fix for use only flexbox in content area */
.next-steps .row {
  margin-bottom: 0;
}

.next-steps .col-md-5 {
  margin-bottom: 3rem;
}

@media (max-width: 768px) {
  .next-steps .col-md-5 {
    margin-bottom: 0;
  }
}

.spinner {
  position: absolute;
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-color: white;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.result-block-container .result-block {
  opacity: 1;
}

.titanLogo {
  background-image: url("https://i.ibb.co/fpKwywG/TITANLOGO.png");
  background-size: cover; /* This will ensure your background image fully covers the element */
  background-repeat: no-repeat; /* This will prevent your background image from repeating */
  height: 2rem;
  width: 2.3rem;
  margin: 0 auto 10px auto;
}
.ninjaLogo {
  background-image: url("https://i.ibb.co/ngbDShb/ninja-Logo.png");
  background-size: cover; /* This will ensure your background image fully covers the element */
  background-repeat: no-repeat; /* This will prevent your background image from repeating */
  height: 2.5rem;
  width: 2.5rem;
  margin: 0 auto 0px auto;

}

.credit-counter {
  display: inline-flex;
  white-space: nowrap;
  align-items: center;
  background-color: #9e9e9e;
  color: #fff;
  padding: 6px 6px;
  border-radius: 2px;
  margin:10px;
  transition: background-color 0.3s ease;
}

.credit-counter:hover {
  background-color: #686868;
}

@media (min-width: 992px) {
  .border-end-lg {
    border-right: 1px solid #e2e5f1 !important;
  }
}


@media (max-width: 768px) {
  .navBackground{
    background-color: white;
  }
  
}
