/* index.css */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: Arial, sans-serif;
}

.top-bar {
  background-color: #333;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: left;
  padding: 0 20px;
  color: #fff;
}

.repel {
  display:flex;
  justify-content: space-between;
}

.top-bar__title {
  font-size: 24px;
  font-weight: bold;
  flex-basis: content;
}

.top-bar__user {
  display: flex;
  align-items: center;
  justify-content: right;
}

.top-bar__pages{
  display: flex;
  align-items: center;
  justify-content: left;
  padding:1em;
  width:20%;
}


.navbar-right {
  position: relative;
}

.dropdown {
  position: relative;
}

.dropbtn {
  background-color: transparent;
  border: none;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
}

.navbtn {
  background-color: transparent;
  border: none;
  color: #fff;
  font-size: 20px;
  flex-basis: 5%;
  padding:0.75em;
  cursor: pointer;
}

.navbtn.active {
  background-color: blue;
  border: none;
  color: #fff;
  font-size: 20px;
  flex-basis: 5%;
  padding:0.75em;
  cursor: pointer;
}

.dropdown-content {
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content button {
  display: block;
  width: 100%;
  text-align: center;
  padding: 8px 16px;
  background-color: #f9f9f9;
  border: none;
  cursor: pointer;
  font-size: 14px;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.contentWrapper {
  margin-top:90px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 234px);
  padding: 20px;

  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
}

.content{
  overflow:visible;
}

@media (max-width: 420px) {
  .content {
    zoom: 0.7;
  }
}


.content::-webkit-scrollbar {
  display: none; /* For Chrome, Safari and Opera */
}

.form-group {
  margin-bottom: 16px;
}

.form-label {
  display: block;
  margin-bottom: 8px;
}

.form-input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

.button {
  padding: 10px 20px;
  background-color: #333;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  margin: 10px;
}

.previous-step-button {
  background-color: #999;
}

.next-step-button {
  background-color: #333;
}

.start-skiptrace-button {
  background-color: #007bff;
}


.download-button {
  background-color: #006cdf;
}

.remove-button {
  background-color: #ff2626;
}

.preview-table-container {
  overflow-x:scroll;
  overflow-y:scroll;
  max-width:80vw;
  height: 250px;
}

.preview-table {
  width: 100%;
  border-collapse: collapse;
}

.preview-table th,
.preview-table td {
  padding: 10px;
  border: 1px solid #ccc;
  white-space: nowrap;
}

.lists-table-container {
  overflow-x:visible;
}

.lists-table {
  width: 100%;
  border-collapse: collapse;
}

.lists-table th,
.lists-table td {
  padding: 5px;
  border: 1px solid #ccc;
  white-space: nowrap;
}

/* Additional formatting */

.content h2 {
  margin-bottom: 20px;
}

.content input[type="file"] {
  margin-bottom: 10px;
}

.content .mapping-field {
  margin-bottom: 10px;
}
.mapping-field select{
  margin:5px;
}
.content .button {
  margin-top: 20px;
}

.content .preview-table {
  margin-bottom: 20px;
}

.content .start-skiptrace-button {
  margin-top: 10px;
}

.content .content {
  margin: 0 auto;
}

.content .form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
}

.loginButton{
  color:red;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.userFile{
background-color: #999;
color:white;
padding:0.5em;
border-radius: 1em;
}

.userFileContainer{
  background-color: #ccc;
  padding:0.5em;
  border-radius: 1em;
  }
  .userFileWrapper{
    max-width: 80vw;
    overflow-x: scroll;
    }
  
    .listNameCell{
      max-width:40vw;
      overflow-x:scroll;
    }

    .styled-table {
      border-collapse: collapse;
      margin: 25px 0;
      font-size: 0.9em;
      font-family: sans-serif;
      min-width: 400px;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  }

 /* The container for the ellipsis and the dropdown */
.dropdown {
  position: relative;
  display: inline-block;
}

.ellipsis-menu {
  font-size: 24px;
  cursor: pointer;
  display: inline-block;
  margin-right:5px;
}

/* Dropdown content, hidden by default */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  top: 25%;      /* align the top of the dropdown with the bottom of the ellipsis */
  left: 0;        /* align the dropdown to the left */
  border-radius: 4px;
  right: 0;
    left: auto;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  
}

/* Change color on hover */
.dropdown-content a:hover {background-color: #f1f1f1;}

/* Show the dropdown menu on hover over the dropdown container */
.dropdown:hover .dropdown-content {
  display: block;
}


.video-container {
  position: relative;
  width: 100%; /* This ensures that it will take up the full width of its parent container. */
  padding-bottom: 51%; /* This gives it a 16:9 aspect ratio. */
  overflow: hidden;
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding-left: 5%;
  padding-right:5%;
}

.copyable-link {
  border: 1px solid #ccc;
  padding: 10px;
line-height: 250%;
  white-space: nowrap;
  cursor: pointer;
}

.copyable-link code {
  font-family: monospace;
}


@keyframes disappear {
  0% { opacity: 1; }
  100% { opacity: 0; }
}

.copied {
  animation: disappear 3s forwards;
  position:absolute;
  margin-left:5px;
  color: green;
  line-height: 250%;
}


.uploadContainer {
  max-width: 600px;
  margin: 30px auto;

}


.listsStep {
  width:80vw;
  height:80vh;
  max-width: 80vw;
  overflow:visible
}

.listsWrapper{
  position:absolute;
  margin-bottom: 20px;
  padding: 20px;
  background-color: #e9ecef;
  border: 1px solid #dcdcdc;
  border-radius: 5px;;
}

.listsWrapperFull{
  margin-bottom: 20px;
  padding: 20px;
  background-color: #e9ecef;
  border: 1px solid #dcdcdc;
  border-radius: 5px;;
}

.uploadStep {
  margin-bottom: 20px;
  padding: 20px;
  background-color: #e9ecef;
  border: 1px solid #dcdcdc;
  border-radius: 5px;
}

.uploadStepHeader {
  margin: 0;
  padding-bottom: 10px;
  border-bottom: 1px solid #ddd;
  font-size: 1.5em;
  color: #333;
}

.uploadStepContent {
  margin-top: 20px;
}

.uploadFileInput {
  margin-top: 10px;
}

.uploadInfo {
  margin-top: 10px;
  padding: 10px;
  background-color: #dff0d8;
  border: 1px solid #d6e9c6;
  border-radius: 4px;
}

.uploadInfo a {
  text-decoration: none;
  color: #3c763d;
}

.uploadButton {
  display: inline-block;
  margin-top: 10px;
  padding: 10px 15px;
  font-size: 1em;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.uploadButton:hover {
  background-color: #0056b3;
}

.uploadVideo {
  margin-top: 20px;
  padding: 20px;
  width:440px;
  border: 1px solid #ccc;
  background-color: #f9f9f9;
  text-align: center;
}

.uploadVideoContainer {
display:flex;
width:100%;
justify-content: center;
}


.invisible{
  display:none;
}

.nav-item{
  white-space: nowrap;
}

.contact{
  background: #2487ce !important;
}

.blueButton{
  margin: 10px;
}

li .nav-link{
  margin-right:16px !important;
}