.text-gradient-primary {
  background: linear-gradient(to right, #6366f1, #8B5CF6, #D946EF);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 600;
  line-height: 1.3;
}


.landing-hero{
  background-repeat: no-repeat;
  background-position: center;
  background-size:cover;
}

.landing-hero::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(to right, rgba(238,130,238, 0.05), rgba(238,130,238, 0.3)); /* Using a very light pink color and fading it from 0.25 to 0.5 */

  pointer-events: none; /* This ensures the overlay doesn't interfere with any interactions */
  z-index: 0;
}


.ninjaNav{
background-color:transparent !important;
box-shadow:none !important;
padding:20px;
margin-bottom:-90px;
width:100%;
z-index: 50;
}
